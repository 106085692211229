import { fork, takeLatest } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    getAllAccessPriceSaga,
    getAllAccessSaga,
} from '../sagas/all-access.saga';
import {
    analysisPriceSaga,
    dacNotifySaga,
    getAIUploadUrlSaga,
    getAnalysisMentorSaga,
    getAnalysisSaga,
    userAISaga,
    userDACSaga,
} from './analysis.saga';
import {
    authCheckSaga,
    forgotPasswordSaga,
    resetPasswordSaga,
    sendOTPSaga,
    signInOTPSaga,
    signinSaga,
    signoutSaga,
    signUpOTPSaga,
    signupSaga,
    socialAccountLinkSaga,
    socialAccountUnLinkSaga,
    verifySignupSaga,
} from './auth.saga';
import {
    browsePackagePriceSaga,
    coursePriceSaga,
    getLowestPriceSaga,
    getPaymentKeysSaga,
    packagePriceSaga,
    relatedProductPriceSaga,
    singleCoursePriceSaga,
} from './cart-payment.saga';
import { couponListSaga } from './coupan.saga';
import {
    courseListSaga,
    courseSaga,
    getCourseDetailsSaga,
    relatedCourseSaga,
    userCourseSaga,
} from './course.saga';
import {
    analysisIntroSaga,
    getWordSaga,
    homeBannerSaga,
    homeIntroSaga,
} from './home.saga';
import { mentorSaga } from './mentor.saga';
import {
    getPackageDetailSaga,
    getPackageSaga,
    getRelatedPackageSaga,
    getTrialPackSaga,
    userPackageSaga,
} from './package.saga';
import {
    getRedeemPassListSaga,
    redeemPassSaga,
    verifyPassSaga,
} from './pass.saga';
import {
    getCountrySaga,
    getProductPricesSaga,
    makePaymentSaga,
    paymentSummarySaga,
} from './payment.saga';
import {
    changeEmailSaga,
    changePasswordSaga,
    editProfileSaga,
    generatePasswordSaga,
    userPurchaseSaga,
    userSubscriptionSaga,
} from './profile.saga';
import {
    accessLessonSaga,
    addBookmarkSaga,
    deleteBookmarkSaga,
    enableWatchSaga,
    getCourseBookmarkSaga,
    getLessonBookmarkSaga,
    mediaTrackingSaga,
    setWatchTimeSaga,
} from './watch.saga';

function* watcher() {
    yield takeLatest(types.SIGNUP, signupSaga);
    yield takeLatest(types.SIGNIN, signinSaga);
    yield takeLatest(types.AUTHCHECK, authCheckSaga);
    yield takeLatest(types.SIGNOUT, signoutSaga);
    yield takeLatest(types.COURSE, courseSaga);
    yield takeLatest(types.COURSE_LIST, courseListSaga);
    yield takeLatest(types.GET_MENTOR, mentorSaga);
    yield takeLatest(types.GET_COURSE_DETAILS, getCourseDetailsSaga);
    yield takeLatest(types.RELATED_COURSE, relatedCourseSaga);
    yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga);
    yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeLatest(types.GENERATE_PASSWORD, generatePasswordSaga);
    yield takeLatest(types.CHANGE_EMAIL, changeEmailSaga);
    yield takeLatest(types.USER_PURCHASE, userPurchaseSaga);
    yield takeLatest(types.USER_SUBSCRIPTION, userSubscriptionSaga);
    yield takeLatest(types.GET_PACKAGE, getPackageSaga);
    yield takeLatest(types.GET_PACKAGE_DETAIL, getPackageDetailSaga);
    yield takeLatest(types.GET_RELATED_PACKAGE, getRelatedPackageSaga);
    yield takeLatest(types.EDIT_PROFILE, editProfileSaga);
    yield takeLatest(types.HOME_INTRO, homeIntroSaga);
    yield takeLatest(types.ANALYSIS_INTRO, analysisIntroSaga);
    yield takeLatest(types.RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(types.ENABLE_WATCH, enableWatchSaga);
    yield takeLatest(types.ACCESS_LESSON, accessLessonSaga);
    yield takeLatest(types.SET_WATCHTIME, setWatchTimeSaga);
    yield takeLatest(types.ADD_BOOKMARK, addBookmarkSaga);
    yield takeLatest(types.GET_LESSON_BOOKMARK, getLessonBookmarkSaga);
    yield takeLatest(types.GET_COURSE_BOOKMARK, getCourseBookmarkSaga);
    yield takeLatest(types.DELETE_BOOKMARK, deleteBookmarkSaga);
    yield takeLatest(types.GET_ANALYSIS, getAnalysisSaga);
    yield takeLatest(types.GET_AI_UPLOAD_URL, getAIUploadUrlSaga);
    yield takeLatest(types.GET_ANALYSIS_MENTOR, getAnalysisMentorSaga);
    yield takeLatest(types.GET_WORD, getWordSaga);
    yield takeLatest(types.GET_PAYMENT_KEYS, getPaymentKeysSaga);
    yield takeLatest(types.SOCIAL_ACCOUNT_LINK, socialAccountLinkSaga);
    yield takeLatest(types.SOCIAL_ACCOUNT_UNLINK, socialAccountUnLinkSaga);
    yield takeLatest(types.COURSE_PRICE, coursePriceSaga);
    yield takeLatest(types.SINGLE_COURSE_PRICE, singleCoursePriceSaga);
    yield takeLatest(types.RELATED_PRODUCT_PRICE, relatedProductPriceSaga);
    yield takeLatest(types.PACKAGE_PRICE, packagePriceSaga);
    yield takeLatest(types.BROWSE_PACKAGE_PRICE, browsePackagePriceSaga);
    yield takeLatest(types.LOWEST_PRICE, getLowestPriceSaga);
    yield takeLatest(types.VERIFY_PASS, verifyPassSaga);
    yield takeLatest(types.REDEEM_PASS, redeemPassSaga);
    yield takeLatest(types.GET_REDEEM_PASS_LIST, getRedeemPassListSaga);
    yield takeLatest(types.GET_TRIAL_PACK, getTrialPackSaga);
    yield takeLatest(types.GET_COUNTRY, getCountrySaga);
    yield takeLatest(types.MAKE_PAYMENT, makePaymentSaga);
    yield takeLatest(types.GET_PRODUCT_PRICES, getProductPricesSaga);
    yield takeLatest(types.PAYMENT_SUMMARY, paymentSummarySaga);
    yield takeLatest(types.VERIFY_SIGNUP, verifySignupSaga);
    yield takeLatest(types.SEND_OTP, sendOTPSaga);
    yield takeLatest(types.MEDIA_TRACKING, mediaTrackingSaga);
    yield takeLatest(types.SIGNIN_OTP, signInOTPSaga);
    yield takeLatest(types.SIGNUP_OTP, signUpOTPSaga);
    yield takeLatest(types.COUPON_LIST, couponListSaga);
    yield takeLatest(types.ANALYSIS_PRICES, analysisPriceSaga);
    yield takeLatest(types.HOME_BANNER, homeBannerSaga);
    yield takeLatest(types.ALL_ACCESS, getAllAccessSaga);
    yield takeLatest(types.ALL_ACCESS_PRICE, getAllAccessPriceSaga);
    yield takeLatest(types.DAC_NOTIFY, dacNotifySaga);
    yield takeLatest(types.USER_AI, userAISaga);
    yield takeLatest(types.USER_DAC, userDACSaga);
    yield takeLatest(types.USER_COURSE, userCourseSaga);
    yield takeLatest(types.USER_PACKAGE, userPackageSaga);
}

function* startForman() {
    yield fork(watcher);
}

export default startForman;
