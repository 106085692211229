import { put, call } from 'redux-saga/effects';
import {
    enableWatchService,
    accessLessonService,
    setWatchTimeService,
    addBookmarkService,
    getLessonBookmarkService,
    getCourseBookmarkService,
    deleteBookmarkService,
    mediaTrackingService,
} from '../services/watch.service';

import * as types from '../action.types';

function* enableWatchSaga() {
    const response = yield call(enableWatchService);

    if (response && !response.error) {
        yield put({ type: types.ENABLE_WATCH_SUCCESS, response });
    } else {
        yield put({ type: types.ENABLE_WATCH_ERROR, response });
    }
}

function* accessLessonSaga(payload) {
    const response = yield call(accessLessonService, payload);

    if (response && !response.error) {
        yield put({ type: types.ACCESS_LESSON_SUCCESS, response });
    } else {
        yield put({ type: types.ACCESS_LESSON_ERROR, response });
    }
}

function* setWatchTimeSaga(payload) {
    const response = yield call(setWatchTimeService, payload);

    if (response && !response.error) {
        yield put({ type: types.SET_WATCHTIME_SUCCESS, response });
    } else {
        yield put({ type: types.SET_WATCHTIME_ERROR, response });
    }
}

function* addBookmarkSaga(payload) {
    const response = yield call(addBookmarkService, payload);

    if (response && !response.error) {
        yield put({ type: types.ADD_BOOKMARK_SUCCESS, response });
    } else {
        yield put({ type: types.ADD_BOOKMARK_ERROR, response });
    }
}

function* getLessonBookmarkSaga(payload) {
    const response = yield call(getLessonBookmarkService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_LESSON_BOOKMARK_SUCCESS, response });
    } else {
        yield put({ type: types.GET_LESSON_BOOKMARK_ERROR, response });
    }
}

function* getCourseBookmarkSaga(payload) {
    const response = yield call(getCourseBookmarkService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_COURSE_BOOKMARK_SUCCESS, response });
    } else {
        yield put({ type: types.GET_COURSE_BOOKMARK_ERROR, response });
    }
}

function* deleteBookmarkSaga(payload) {
    const response = yield call(deleteBookmarkService, payload);

    if (response && !response.error) {
        yield put({ type: types.DELETE_BOOKMARK_SUCCESS, response });
    } else {
        yield put({ type: types.DELETE_BOOKMARK_ERROR, response });
    }
}

function* mediaTrackingSaga(payload) {
    const response = yield call(mediaTrackingService, payload);

    if (response && !response.error) {
        yield put({ type: types.MEDIA_TRACKING_SUCCESS, response });
    } else {
        yield put({ type: types.MEDIA_TRACKING_ERROR, response });
    }
}

export {
    enableWatchSaga,
    accessLessonSaga,
    setWatchTimeSaga,
    addBookmarkSaga,
    getLessonBookmarkSaga,
    getCourseBookmarkSaga,
    deleteBookmarkSaga,
    mediaTrackingSaga
};
