import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    getCountryService,
    getProductPricesService,
    makePaymentService,
    paymentSummaryService,
} from '../services/payment.service';

function* getCountrySaga() {
    const response = yield call(getCountryService);

    if (response && !response.error) {
        yield put({ type: types.GET_COUNTRY_SUCCESS, response });
    } else {
        yield put({ type: types.GET_COUNTRY_ERROR, response });
    }
}

function* makePaymentSaga(payload) {
    const response = yield call(makePaymentService, payload);

    if (response && !response.error) {
        yield put({ type: types.MAKE_PAYMENT_SUCCESS, response });
    } else {
        yield put({ type: types.MAKE_PAYMENT_ERROR, response });
    }
}

function* getProductPricesSaga(payload) {
    const response = yield call(getProductPricesService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_PRODUCT_PRICES_SUCCESS, response });
    } else {
        yield put({ type: types.GET_PRODUCT_PRICES_ERROR, response });
    }
}

function* paymentSummarySaga(payload) {
    const response = yield call(paymentSummaryService, payload);

    if (response && !response.error) {
        yield put({ type: types.PAYMENT_SUMMARY_SUCCESS, response });
    } else {
        yield put({ type: types.PAYMENT_SUMMARY_ERROR, response });
    }
}

export {
    getCountrySaga,
    makePaymentSaga,
    getProductPricesSaga,
    paymentSummarySaga,
};
