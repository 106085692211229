import * as types from '../action.types';

const initialState = {};

const changePasswordReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                success: true,
                message: response.message,
            };
        case types.CHANGE_PASSWORD_ERROR:
            return response;
        default:
            return state;
    }
};

const generatePasswordReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GENERATE_PASSWORD_SUCCESS:
            return {
                success: true,
                message: response.message,
            };
        case types.GENERATE_PASSWORD_ERROR:
            return response;
        default:
            return state;
    }
};

const changeEmailReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CHANGE_EMAIL_SUCCESS:
            return {
                success: true,
                message: response.message,
            };
        case types.CHANGE_EMAIL_ERROR:
            return response;
        default:
            return state;
    }
};

const userPurchaseReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.USER_PURCHASE_SUCCESS:
            return response;
        case types.USER_PURCHASE_ERROR:
            return response;
        default:
            return state;
    }
};

const userSubscriptionReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.USER_SUBSCRIPTION_SUCCESS:
            return response;
        case types.USER_SUBSCRIPTION_ERROR:
            return response;
        default:
            return state;
    }
};

const editProfileReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.EDIT_PROFILE_SUCCESS:
            return response;
        case types.EDIT_PROFILE_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    changePasswordReducer,
    generatePasswordReducer,
    changeEmailReducer,
    userPurchaseReducer,
    userSubscriptionReducer,
    editProfileReducer,
};
