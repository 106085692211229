import Cookie from 'js-cookie';
import axios from '../utils/axios.instance';
import { REACT_APP_API_URL } from './index.service';

const courseService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/course/list`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const courseListService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/course/list`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getCourseDetailsService = (req) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/course/${req.data}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const relatedCourseService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/course/related?&skip=${data.mentor}&category=${data.category}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const userCourseService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/user/courses`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    courseService,
    getCourseDetailsService,
    relatedCourseService,
    courseListService,
    userCourseService,
};
