import Cookie from 'js-cookie';
import axios from '../utils/axios.instance';
import { REACT_APP_API_URL } from './index.service';

const getAnalysisService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/analysis/list`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getAIUploadUrlService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/analysis/url?type=AI`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getAnalysisMentorService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/analysis/mentor/list`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const analysisPriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const dacNotifyService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/analysis/notify/${data?.mentor_id}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err?.response?.data;
        });
};

const userAIService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/user/ai`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err?.response?.data;
        });
};

const userDACService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/user/dac`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err?.response?.data;
        });
};

export {
    getAnalysisService,
    getAIUploadUrlService,
    getAnalysisMentorService,
    analysisPriceService,
    dacNotifyService,
    userAIService,
    userDACService,
};
