import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const mentorService = (req) => {
    let reqUrl = `${REACT_APP_API_URL}/course/browse`;
    if (req.data && req.data.category && req.data.category !== 'All') {
        reqUrl = `${REACT_APP_API_URL}/course/browse?category=${req.data.category}`;
    }

    return axios
        .get(
            reqUrl,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export { mentorService };
