import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const getCountryService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/payment/country`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const makePaymentService = ({ data }) => {
    return axios
        .post(
            `${REACT_APP_API_URL}/payment/razorpay/${data.type}`,
            {
                ...data.product,
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookie.get('token')}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getProductPricesService = ({ data }) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (data) {
        data.map((obj, i) => {
            if (i === 0) {
                url = url.concat(`${obj}`);
            } else {
                url = url.concat(`,${obj}`);
            }
            return null;
        });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const paymentSummaryService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/payment/summary`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    getCountryService,
    makePaymentService,
    getProductPricesService,
    paymentSummaryService,
};
