import { combineReducers } from 'redux';
import {
    getAllAccessPriceReducer,
    getAllAccessReducer,
} from '../reducers/all-access.reducer';
import {
    analysisPriceReducer,
    dacNotifyReducer,
    getAIUploadUrlReducer,
    getAnalysisMentorReducer,
    getAnalysisReducer,
    userAIReducer,
    userDACReducer,
} from './analysis.reducer';
import {
    authCheckReducer,
    clearChangePassReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    sendOTPReducer,
    signInOTPReducer,
    signinReducer,
    signoutReducer,
    signUpOTPReducer,
    signupReducer,
    socialAccountLinkReducer,
    socialAccountUnLinkReducer,
    verifySignupReducer,
} from './auth.reducer';
import {
    browsePackagePriceReducer,
    coursePriceReducer,
    getLowestPriceReducer,
    getPaymentKeysReducer,
    packagePriceReducer,
    relatedProductPriceReducer,
    singleCoursePriceReducer,
} from './cart-payment.reducer';
import { couponListReducer } from './coupan.reducer';
import {
    clearCoursePriceReducer,
    courseListReducer,
    courseReducer,
    getCourseDetailsReducer,
    relatedCourseReducer,
    clearCourseDetailReducer,
    userCourseReducer,
} from './course.reducer';
import {
    analysisIntroReducer,
    getWordReducer,
    homeBannerReducer,
    homeIntroReducer,
} from './home.reducer';
import { mentorReducer } from './mentor.reducer';
import {
    clearPackagePriceReducer,
    getPackageDetailReducer,
    getPackageReducer,
    getRelatedPackageReducer,
    getTrialPackReducer,
    userPackageReducer,
} from './package.reducer';
import {
    clearRedeemPassReducer,
    clearVerifyPassReducer,
    getRedeemPassListReducer,
    redeemPassReducer,
    verifyPassReducer,
} from './pass.reducer';
import {
    clearProductPricesReducer,
    getCountryReducer,
    getProductPricesReducer,
    makePaymentReducer,
    paymentSummaryReducer,
} from './payment.reducer';
import {
    changeEmailReducer,
    changePasswordReducer,
    editProfileReducer,
    generatePasswordReducer,
    userPurchaseReducer,
    userSubscriptionReducer,
} from './profile.reducer';
import {
    accessLessonReducer,
    addBookmarkReducer,
    deleteBookmarkReducer,
    enableWatchReducer,
    getCourseBookmarkReducer,
    getLessonBookmarkReducer,
    mediaTrackingReducer,
    setWatchTimeReducer,
} from './watch.reducer';

const allReducers = combineReducers({
    signupReducer,
    signinReducer,
    authCheckReducer,
    signoutReducer,
    courseReducer,
    courseListReducer,
    mentorReducer,
    getCourseDetailsReducer,
    relatedCourseReducer,
    changePasswordReducer,
    forgotPasswordReducer,
    changeEmailReducer,
    getPackageReducer,
    getPackageDetailReducer,
    getRelatedPackageReducer,
    userPurchaseReducer,
    userSubscriptionReducer,
    editProfileReducer,
    generatePasswordReducer,
    homeIntroReducer,
    analysisIntroReducer,
    resetPasswordReducer,
    enableWatchReducer,
    accessLessonReducer,
    setWatchTimeReducer,
    addBookmarkReducer,
    getLessonBookmarkReducer,
    getCourseBookmarkReducer,
    deleteBookmarkReducer,
    getAnalysisReducer,
    getAIUploadUrlReducer,
    getAnalysisMentorReducer,
    getWordReducer,
    getPaymentKeysReducer,
    socialAccountLinkReducer,
    socialAccountUnLinkReducer,
    coursePriceReducer,
    singleCoursePriceReducer,
    relatedProductPriceReducer,
    packagePriceReducer,
    clearCoursePriceReducer,
    clearPackagePriceReducer,
    browsePackagePriceReducer,
    getLowestPriceReducer,
    verifyPassReducer,
    redeemPassReducer,
    getRedeemPassListReducer,
    clearVerifyPassReducer,
    clearRedeemPassReducer,
    getTrialPackReducer,
    getCountryReducer,
    makePaymentReducer,
    getProductPricesReducer,
    clearProductPricesReducer,
    paymentSummaryReducer,
    verifySignupReducer,
    sendOTPReducer,
    mediaTrackingReducer,
    clearChangePassReducer,
    signInOTPReducer,
    signUpOTPReducer,
    couponListReducer,
    analysisPriceReducer,
    homeBannerReducer,
    getAllAccessReducer,
    getAllAccessPriceReducer,
    dacNotifyReducer,
    userAIReducer,
    userDACReducer,
    clearCourseDetailReducer,
    userCourseReducer,
    userPackageReducer,
});

// for cleaning the store once the error is set from api response
// this is used when the component is unmounted and requires a fresh start
const rootReducer = (state, action) => {
    if (action.type === 'ERROR_CLEANUP') {
        state.signinReducer = {};
        state.signupReducer = {};
        state.sendOTPReducer = {};
        state.signInOTPReducer = {};
        state.signUpOTPReducer = {};
    }

    if (action.type === 'ANALYSIS_PAYMENT_CLEANUP') {
    }
    if (action.type === 'CLEAR_COURSE_PRICE') {
        state.singleCoursePriceReducer = {};
        state.relatedProductPriceReducer = {};
        state.coursePriceReducer = {};
    }
    if (action.type === 'CLEAR_PACKAGE_PRICE') {
        state.singleCoursePriceReducer = {};
        state.relatedProductPriceReducer = {};
        state.coursePriceReducer = {};
    }
    if (action.type === 'CLEAR_VERIFY_PASS') {
        state.verifyPassReducer = {};
    }
    if (action.type === 'CLEAR_REDEEM_PASS') {
        state.redeemPassReducer = {};
    }
    if (action.type === 'CLEAR_PAYMENT_STATE') {
        state.makePaymentReducer = {};
    }
    if (action.type === 'CLEAR_PRODUCT_PRICES') {
        state.getProductPricesReducer = {};
    }

    if (action.type === 'CHANGE_PASS_CLEANUP') {
        state.changePasswordReducer = {};
    }

    if (action.type === 'CLEAR_DAC_NOTIFY') {
        state.dacNotifyReducer = {};
    }
    if (action.type === 'CLEAR_COURSE_DETAIL') {
        state.getCourseDetailsReducer = {};
    }

    return allReducers(state, action);
};

export default rootReducer;
