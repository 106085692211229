import * as types from '../action.types';

const initialState = {};

export const getAllAccessReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ALL_ACCESS_SUCCESS:
            return response;
        case types.ALL_ACCESS_ERROR:
            return response;
        default:
            return state;
    }
};

export const getAllAccessPriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ALL_ACCESS_PRICE_SUCCESS:
            return response;
        case types.ALL_ACCESS_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};
