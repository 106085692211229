import * as types from '../action.types';

const initialState = {};

const courseReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.COURSE_SUCCESS:
            return response;
        case types.COURSE_ERROR:
            return response;
        default:
            return state;
    }
};

const getCourseDetailsReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_COURSE_DETAILS_SUCCESS:
            return response;
        case types.GET_COURSE_DETAILS_ERROR:
            return response;
        default:
            return state;
    }
};

const relatedCourseReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.RELATED_COURSE_SUCCESS:
            return response;
        case types.RELATED_COURSE_ERROR:
            return response;
        default:
            return state;
    }
};

const courseListReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.COURSE_LIST_SUCCESS:
            return response;
        case types.COURSE_LIST_ERROR:
            return response;
        default:
            return state;
    }
};

const clearCoursePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_COURSE_PRICE_SUCCESS:
            return response;
        case types.CLEAR_COURSE_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const clearCourseDetailReducer = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case types.CLEAR_COURSE_DETAIL_SUCCESS:
            return response;
        case types.CLEAR_COURSE_DETAIL_ERROR:
            return response;
        default:
            return state;
    }
};

const userCourseReducer = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case types.USER_COURSE_SUCCESS:
            return response;
        case types.USER_COURSE_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    courseReducer,
    getCourseDetailsReducer,
    relatedCourseReducer,
    courseListReducer,
    clearCoursePriceReducer,
    clearCourseDetailReducer,
    userCourseReducer,
};
