import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const verifyPassService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/product/code/verify/${data.code}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const redeemPassService = ({ data }) => {
    return axios
        .post(
            `${REACT_APP_API_URL}/product/code/redeem`,
            data,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getRedeemPassListService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/product/code/redeem`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export { verifyPassService, redeemPassService, getRedeemPassListService };
