import * as types from '../action.types';

const initialState = {};

const getPaymentKeysReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_PAYMENT_KEYS_SUCCESS:
            return response;
        case types.GET_PAYMENT_KEYS_ERROR:
            return response;
        default:
            return state;
    }
};

const coursePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.COURSE_PRICE_SUCCESS:
            return response;
        case types.COURSE_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const singleCoursePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SINGLE_COURSE_PRICE_SUCCESS:
            return response;
        case types.SINGLE_COURSE_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const relatedProductPriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.RELATED_PRODUCT_PRICE_SUCCESS:
            return response;
        case types.RELATED_PRODUCT_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const packagePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.PACKAGE_PRICE_SUCCESS:
            return response;
        case types.PACKAGE_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const browsePackagePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.BROWSE_PACKAGE_PRICE_SUCCESS:
            return response;
        case types.BROWSE_PACKAGE_PRICE_ERROR:
        default:
            return state;
    }
};

const getLowestPriceReducer = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case types.LOWEST_PRICE_SUCCESS:
            return response;
        case types.LOWEST_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    getPaymentKeysReducer,
    coursePriceReducer,
    singleCoursePriceReducer,
    relatedProductPriceReducer,
    packagePriceReducer,
    browsePackagePriceReducer,
    getLowestPriceReducer,
};
