import { put, call } from 'redux-saga/effects';
import {
    verifyPassService,
    redeemPassService,
    getRedeemPassListService,
} from '../services/pass.service';

import * as types from '../action.types';

function* verifyPassSaga(payload) {
    const response = yield call(verifyPassService, payload);

    if (response && !response.error) {
        yield put({ type: types.VERIFY_PASS_SUCCESS, response });
    } else {
        yield put({ type: types.VERIFY_PASS_ERROR, response });
    }
}

function* redeemPassSaga(payload) {
    const response = yield call(redeemPassService, payload);

    if (response && !response.error) {
        yield put({ type: types.REDEEM_PASS_SUCCESS, response });
        // window.location.replace('/profile');
    } else {
        yield put({ type: types.REDEEM_PASS_ERROR, response });
    }
}

function* getRedeemPassListSaga() {
    const response = yield call(getRedeemPassListService);

    if (response && !response.error) {
        yield put({ type: types.GET_REDEEM_PASS_LIST_SUCCESS, response });
    } else {
        yield put({ type: types.GET_REDEEM_PASS_LIST_ERROR, response });
    }
}

export { verifyPassSaga, redeemPassSaga, getRedeemPassListSaga };
