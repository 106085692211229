import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    browsePackagePriceService,
    coursePriceService,
    getLowestPriceService,
    getPaymentKeysService,
    packagePriceService,
    relatedProductPriceService,
    singleCoursePriceService,
} from '../services/cart-payment.service';

function* getPaymentKeysSaga() {
    const response = yield call(getPaymentKeysService);

    if (response && !response.error) {
        yield put({ type: types.GET_PAYMENT_KEYS_SUCCESS, response });
    } else {
        yield put({ type: types.GET_PAYMENT_KEYS_ERROR, response });
    }
}


function* coursePriceSaga(payload) {
    const response = yield call(coursePriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.COURSE_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.COURSE_PRICE_ERROR, response });
    }
}

function* singleCoursePriceSaga(payload) {
    const response = yield call(singleCoursePriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.SINGLE_COURSE_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.SINGLE_COURSE_PRICE_ERROR, response });
    }
}

function* relatedProductPriceSaga(payload) {
    const response = yield call(relatedProductPriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.RELATED_PRODUCT_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.RELATED_PRODUCT_PRICE_ERROR, response });
    }
}

function* packagePriceSaga(payload) {
    const response = yield call(packagePriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.PACKAGE_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.PACKAGE_PRICE_ERROR, response });
    }
}

function* getLowestPriceSaga(payload) {
    const response = yield call(getLowestPriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.LOWEST_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.LOWEST_PRICE_ERROR, response });
    }
}

function* browsePackagePriceSaga(payload) {
    const response = yield call(browsePackagePriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.BROWSE_PACKAGE_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.BROWSE_PACKAGE_PRICE_ERROR, response });
    }
}

export {
    getPaymentKeysSaga,
    coursePriceSaga,
    singleCoursePriceSaga,
    relatedProductPriceSaga,
    packagePriceSaga,
    browsePackagePriceSaga,
    getLowestPriceSaga,
};
