import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const couponListService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/coupon/list`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export { couponListService };
