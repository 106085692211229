import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export default function FallBackLoader() {
    return (
        <Box width="100%" mt={5} mb={5} textAlign="center">
            <CircularProgress color="secondary" />
        </Box>
    );
}
