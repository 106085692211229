export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const AUTHCHECK = 'AUTHCHECK';
export const AUTHCHECK_SUCCESS = 'AUTHCHECK_SUCCESS';
export const AUTHCHECK_ERROR = 'AUTHCHECK_ERROR';

export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const COURSE = 'COURSE';
export const COURSE_SUCCESS = 'COURSE_SUCCESS';
export const COURSE_ERROR = 'COURSE_ERROR';

export const GET_MENTOR = 'GET_MENTOR';
export const GET_MENTOR_SUCCESS = 'GET_MENTOR_SUCCESS';
export const GET_MENTOR_ERROR = 'GET_MENTOR_ERROR';

export const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export const GET_COURSE_DETAILS_SUCCESS = 'GET_COURSE_DETAILS_SUCCESS';
export const GET_COURSE_DETAILS_ERROR = 'GET_COURSE_DETAILS_ERROR';

export const RELATED_COURSE = 'RELATED_COURSE';
export const RELATED_COURSE_SUCCESS = 'RELATED_COURSE_SUCCESS';
export const RELATED_COURSE_ERROR = 'RELATED_COURSE_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const GENERATE_PASSWORD = 'GENERATE_PASSWORD';
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS';
export const GENERATE_PASSWORD_ERROR = 'GENERATE_PASSWORD_ERROR';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';

export const GET_PACKAGE = 'GET_PACKAGE';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_ERROR = 'GET_PACKAGE_ERROR';

export const GET_PACKAGE_DETAIL = 'GET_PACKAGE_DETAIL';
export const GET_PACKAGE_DETAIL_SUCCESS = 'GET_PACKAGE_DETAIL_SUCCESS';
export const GET_PACKAGE_DETAIL_ERROR = 'GET_PACKAGE_DETAIL_ERROR';

export const GET_RELATED_PACKAGE = 'GET_RELATED_PACKAGE';
export const GET_RELATED_PACKAGE_SUCCESS = 'GET_RELATED_PACKAGE_SUCCESS';
export const GET_RELATED_PACKAGE_ERROR = 'GET_RELATED_PACKAGE_ERROR';

export const USER_PURCHASE = 'USER_PURCHASE';
export const USER_PURCHASE_SUCCESS = 'USER_PURCHASE_SUCCESS';
export const USER_PURCHASE_ERROR = 'USER_PURCHASE_ERROR';

export const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION';
export const USER_SUBSCRIPTION_SUCCESS = 'USER_SUBSCRIPTION_SUCCESS';
export const USER_SUBSCRIPTION_ERROR = 'USER_SUBSCRIPTION_ERROR';

export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';

export const HOME_INTRO = 'HOME_INTRO';
export const HOME_INTRO_SUCCESS = 'HOME_INTRO_SUCCESS';
export const HOME_INTRO_ERROR = 'HOME_INTRO_ERROR';

export const ANALYSIS_INTRO = 'ANALYSIS_INTRO';
export const ANALYSIS_INTRO_SUCCESS = 'ANALYSIS_INTRO_SUCCESS';
export const ANALYSIS_INTRO_ERROR = 'ANALYSIS_INTRO_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const ERROR_CLEANUP = 'ERROR_CLEANUP';

export const ENABLE_WATCH = 'ENABLE_WATCH';
export const ENABLE_WATCH_SUCCESS = 'ENABLE_WATCH_SUCCESS';
export const ENABLE_WATCH_ERROR = 'ENABLE_WATCH_ERROR';

export const ACCESS_LESSON = 'ACCESS_LESSON';
export const ACCESS_LESSON_SUCCESS = 'ACCESS_LESSON_SUCCESS';
export const ACCESS_LESSON_ERROR = 'ACCESS_LESSON_ERROR';

export const SET_WATCHTIME = 'SET_WATCHTIME';
export const SET_WATCHTIME_SUCCESS = 'SET_WATCHTIME_SUCCESS';
export const SET_WATCHTIME_ERROR = 'SET_WATCHTIME_ERROR';

export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS';
export const ADD_BOOKMARK_ERROR = 'ADD_BOOKMARK_ERROR';

export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_ERROR = 'DELETE_BOOKMARK_ERROR';

export const GET_LESSON_BOOKMARK = 'GET_LESSON_BOOKMARK';
export const GET_LESSON_BOOKMARK_SUCCESS = 'GET_LESSON_BOOKMARK_SUCCESS';
export const GET_LESSON_BOOKMARK_ERROR = 'GET_LESSON_BOOKMARK_ERROR';

export const GET_COURSE_BOOKMARK = 'GET_COURSE_BOOKMARK';
export const GET_COURSE_BOOKMARK_SUCCESS = 'GET_COURSE_BOOKMARK_SUCCESS';
export const GET_COURSE_BOOKMARK_ERROR = 'GET_COURSE_BOOKMARK_ERROR';

export const GET_ANALYSIS = 'GET_ANALYSIS';
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS';
export const GET_ANALYSIS_ERROR = 'GET_ANALYSIS_ERROR';

export const GET_AI_UPLOAD_URL = 'GET_AI_UPLOAD_URL';
export const GET_AI_UPLOAD_URL_SUCCESS = 'GET_AI_UPLOAD_URL_SUCCESS';
export const GET_AI_UPLOAD_URL_ERROR = 'GET_AI_UPLOAD_URL_ERROR';

export const GET_ANALYSIS_MENTOR = 'GET_ANALYSIS_MENTOR';
export const GET_ANALYSIS_MENTOR_SUCCESS = 'GET_ANALYSIS_MENTOR_SUCCESS';
export const GET_ANALYSIS_MENTOR_ERROR = 'GET_ANALYSIS_MENTOR_ERROR';

export const GET_WORD = 'GET_WORD';
export const GET_WORD_SUCCESS = 'GET_WORD_SUCCESS';
export const GET_WORD_ERROR = 'GET_WORD_ERROR';

export const GET_PAYMENT_KEYS = 'GET_PAYMENT_KEYS';
export const GET_PAYMENT_KEYS_SUCCESS = 'GET_PAYMENT_KEYS_SUCCESS';
export const GET_PAYMENT_KEYS_ERROR = 'GET_PAYMENT_KEYS_ERROR';

export const SOCIAL_ACCOUNT_LINK = 'SOCIAL_ACCOUNT_LINK';
export const SOCIAL_ACCOUNT_LINK_SUCCESS = 'SOCIAL_ACCOUNT_LINK_SUCCESS';
export const SOCIAL_ACCOUNT_LINK_ERROR = 'SOCIAL_ACCOUNT_LINK_ERROR';

export const SOCIAL_ACCOUNT_UNLINK = 'SOCIAL_ACCOUNT_UNLINK';
export const SOCIAL_ACCOUNT_UNLINK_SUCCESS = 'SOCIAL_ACCOUNT_UNLINK_SUCCESS';
export const SOCIAL_ACCOUNT_UNLINK_ERROR = 'SOCIAL_ACCOUNT_UNLINK_ERROR';

export const COURSE_LIST = 'COURSE_LIST';
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS';
export const COURSE_LIST_ERROR = 'COURSE_LIST_ERROR';

export const COURSE_PRICE = 'COURSE_PRICE';
export const COURSE_PRICE_SUCCESS = 'COURSE_PRICE_SUCCESS';
export const COURSE_PRICE_ERROR = 'COURSE_PRICE_ERROR';

export const PACKAGE_PRICE = 'PACKAGE_PRICE';
export const PACKAGE_PRICE_SUCCESS = 'PACKAGE_PRICE_SUCCESS';
export const PACKAGE_PRICE_ERROR = 'PACKAGE_PRICE_ERROR';

export const SINGLE_COURSE_PRICE = 'SINGLE_COURSE_PRICE';
export const SINGLE_COURSE_PRICE_SUCCESS = 'SINGLE_COURSE_PRICE_SUCCESS';
export const SINGLE_COURSE_PRICE_ERROR = 'SINGLE_COURSE_PRICE_ERROR';

export const RELATED_PRODUCT_PRICE = 'RELATED_PRODUCT_PRICE';
export const RELATED_PRODUCT_PRICE_SUCCESS = 'RELATED_PRODUCT_PRICE_SUCCESS';
export const RELATED_PRODUCT_PRICE_ERROR = 'RELATED_PRODUCT_PRICE_ERROR';

export const CLEAR_COURSE_PRICE = 'CLEAR_COURSE_PRICE';
export const CLEAR_COURSE_PRICE_SUCCESS = 'CLEAR_COURSE_PRICE_SUCCESS';
export const CLEAR_COURSE_PRICE_ERROR = 'CLEAR_COURSE_PRICE_ERROR';

export const CLEAR_COURSE_DETAIL = 'CLEAR_COURSE_DETAIL';
export const CLEAR_COURSE_DETAIL_SUCCESS = 'CLEAR_COURSE_DETAIL_SUCCESS';
export const CLEAR_COURSE_DETAIL_ERROR = 'CLEAR_COURSE_DETAIL_ERROR';

export const CLEAR_PACKAGE_PRICE = 'CLEAR_PACKAGE_PRICE';
export const CLEAR_PACKAGE_PRICE_SUCCESS = 'CLEAR_PACKAGE_PRICE_SUCCESS';
export const CLEAR_PACKAGE_PRICE_ERROR = 'CLEAR_PACKAGE_PRICE_ERROR';

export const BROWSE_PACKAGE_PRICE = 'BROWSE_PACKAGE_PRICE';
export const BROWSE_PACKAGE_PRICE_SUCCESS = 'BROWSE_PACKAGE_PRICE_SUCCESS';
export const BROWSE_PACKAGE_PRICE_ERROR = 'BROWSE_PACKAGE_PRICE_ERROR';

export const LOWEST_PRICE = 'LOWEST_PRICE';
export const LOWEST_PRICE_SUCCESS = 'LOWEST_PRICE_SUCCESS';
export const LOWEST_PRICE_ERROR = 'LOWEST_PRICE_ERROR';

export const VERIFY_PASS = 'VERIFY_PASS';
export const VERIFY_PASS_SUCCESS = 'VERIFY_PASS_SUCCESS';
export const VERIFY_PASS_ERROR = 'VERIFY_PASS_ERROR';

export const CLEAR_VERIFY_PASS = 'CLEAR_VERIFY_PASS';
export const CLEAR_VERIFY_PASS_SUCCESS = 'CLEAR_VERIFY_PASS_SUCCESS';
export const CLEAR_VERIFY_PASS_ERROR = 'CLEAR_VERIFY_PASS_ERROR';

export const REDEEM_PASS = 'REDEEM_PASS';
export const REDEEM_PASS_SUCCESS = 'REDEEM_PASS_SUCCESS';
export const REDEEM_PASS_ERROR = 'REDEEM_PASS_ERROR';

export const CLEAR_REDEEM_PASS = 'CLEAR_REDEEM_PASS';
export const CLEAR_REDEEM_PASS_SUCCESS = 'CLEAR_REDEEM_PASS_SUCCESS';
export const CLEAR_REDEEM_PASS_ERROR = 'CLEAR_REDEEM_PASS_ERROR';

export const GET_REDEEM_PASS_LIST = 'GET_REDEEM_PASS_LIST';
export const GET_REDEEM_PASS_LIST_SUCCESS = 'GET_REDEEM_PASS_LIST_SUCCESS';
export const GET_REDEEM_PASS_LIST_ERROR = 'GET_REDEEM_PASS_LIST_ERROR';

export const GET_TRIAL_PACK = 'GET_TRIAL_PACK';
export const GET_TRIAL_PACK_SUCCESS = 'GET_TRIAL_PACK_SUCCESS';
export const GET_TRIAL_PACK_ERROR = 'GET_TRIAL_PACK_ERROR';

export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';

export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_ERROR = 'MAKE_PAYMENT_ERROR';

export const CLEAR_PAYMENT_STATE = 'CLEAR_PAYMENT_STATE';
export const CLEAR_PAYMENT_STATE_SUCCESS = 'CLEAR_PAYMENT_STATE_SUCCESS';
export const CLEAR_PAYMENT_STATE_ERROR = 'CLEAR_PAYMENT_STATE_ERROR';

export const GET_PRODUCT_PRICES = 'GET_PRODUCT_PRICES';
export const GET_PRODUCT_PRICES_SUCCESS = 'GET_PRODUCT_PRICES_SUCCESS';
export const GET_PRODUCT_PRICES_ERROR = 'GET_PRODUCT_PRICES_ERROR';

export const CLEAR_PRODUCT_PRICES = 'CLEAR_PRODUCT_PRICES';
export const CLEAR_PRODUCT_PRICES_SUCCESS = 'CLEAR_PRODUCT_PRICES_SUCCESS';
export const CLEAR_PRODUCT_PRICES_ERROR = 'CLEAR_PRODUCT_PRICES_ERROR';

export const PAYMENT_SUMMARY = 'PAYMENT_SUMMARY';
export const PAYMENT_SUMMARY_SUCCESS = 'PAYMENT_SUMMARY_SUCCESS';
export const PAYMENT_SUMMARY_ERROR = 'PAYMENT_SUMMARY_ERROR';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';

export const VERIFY_SIGNUP = 'VERIFY_SIGNUP';
export const VERIFY_SIGNUP_SUCCESS = 'VERIFY_SIGNUP_SUCCESS';
export const VERIFY_SIGNUP_ERROR = 'VERIFY_SIGNUP_ERROR';

export const MEDIA_TRACKING = 'MEDIA_TRACKING';
export const MEDIA_TRACKING_SUCCESS = 'MEDIA_TRACKING_SUCCESS';
export const MEDIA_TRACKING_ERROR = 'MEDIA_TRACKING_ERROR';

export const CHANGE_PASS_CLEANUP = 'CHANGE_PASS_CLEANUP';
export const CHANGE_PASS_CLEANUP_SUCCESS = 'CHANGE_PASS_CLEANUP_SUCCESS';
export const CHANGE_PASS_CLEANUP_ERROR = 'CHANGE_PASS_CLEANUP_ERROR';

export const SIGNIN_OTP = 'SIGNIN_OTP';
export const SIGNIN_OTP_SUCCESS = 'SIGNIN_OTP_SUCCESS';
export const SIGNIN_OTP_ERROR = 'SIGNIN_OTP_ERROR';

export const SIGNUP_OTP = 'SIGNUP_OTP';
export const SIGNUP_OTP_SUCCESS = 'SIGNUP_OTP_SUCCESS';
export const SIGNUP_OTP_ERROR = 'SIGNUP_OTP_ERROR';

export const COUPON_LIST = 'COUPON_LIST';
export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS';
export const COUPON_LIST_ERROR = 'COUPON_LIST_ERROR';

export const ANALYSIS_PRICES = 'ANALYSIS_PRICES';
export const ANALYSIS_PRICES_SUCCESS = 'ANALYSIS_PRICES_SUCCESS';
export const ANALYSIS_PRICES_ERROR = 'ANALYSIS_PRICES_ERROR';

export const HOME_BANNER = 'HOME_BANNER';
export const HOME_BANNER_SUCCESS = 'HOME_BANNER_SUCCESS';
export const HOME_BANNER_ERROR = 'HOME_BANNER_ERROR';

export const ALL_ACCESS = 'ALL_ACCESS';
export const ALL_ACCESS_SUCCESS = 'ALL_ACCESS_SUCCESS';
export const ALL_ACCESS_ERROR = 'ALL_ACCESS_ERROR';

export const ALL_ACCESS_PRICE = 'ALL_ACCESS_PRICE';
export const ALL_ACCESS_PRICE_SUCCESS = 'ALL_ACCESS_PRICE_SUCCESS';
export const ALL_ACCESS_PRICE_ERROR = 'ALL_ACCESS_PRICE_ERROR';

export const DAC_NOTIFY = 'DAC_NOTIFY';
export const DAC_NOTIFY_SUCCESS = 'DAC_NOTIFY_SUCCESS';
export const DAC_NOTIFY_ERROR = 'DAC_NOTIFY_ERROR';

export const CLEAR_DAC_NOTIFY = 'CLEAR_DAC_NOTIFY';
export const CLEAR_DAC_NOTIFY_SUCCESS = 'CLEAR_DAC_NOTIFY_SUCCESS';
export const CLEAR_DAC_NOTIFY_ERROR = 'CLEAR_DAC_NOTIFY_ERROR';

export const USER_AI = 'USER_AI';
export const USER_AI_SUCCESS = 'USER_AI_SUCCESS';
export const USER_AI_ERROR = 'USER_AI_ERROR';

export const USER_DAC = 'USER_DAC';
export const USER_DAC_SUCCESS = 'USER_DAC_SUCCESS';
export const USER_DAC_ERROR = 'USER_DAC_ERROR';

export const USER_COURSE = 'USER_COURSE';
export const USER_COURSE_SUCCESS = 'USER_COURSE_SUCCESS';
export const USER_COURSE_ERROR = 'USER_COURSE_ERROR';

export const USER_PACKAGE = 'USER_PACKAGE';
export const USER_PACKAGE_SUCCESS = 'USER_PACKAGE_SUCCESS';
export const USER_PACKAGE_ERROR = 'USER_PACKAGE_ERROR';
