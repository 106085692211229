import { put, call } from 'redux-saga/effects';
import {
    getPackageService,
    getPackageDetailService,
    getRelatedPackageService,
    getTrialPackService,
    userPackageService,
} from '../services/package.service';

import * as types from '../action.types';

function* getPackageSaga() {
    const response = yield call(getPackageService);

    if (response && !response.error) {
        yield put({ type: types.GET_PACKAGE_SUCCESS, response });
    } else {
        yield put({ type: types.GET_PACKAGE_ERROR, response });
    }
}

function* getPackageDetailSaga(payload) {
    const response = yield call(getPackageDetailService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_PACKAGE_DETAIL_SUCCESS, response });
    } else {
        yield put({ type: types.GET_PACKAGE_DETAIL_ERROR, response });
    }
}

function* getRelatedPackageSaga(payload) {
    const response = yield call(getRelatedPackageService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_RELATED_PACKAGE_SUCCESS, response });
    } else {
        yield put({ type: types.GET_RELATED_PACKAGE_ERROR, response });
    }
}

function* getTrialPackSaga() {
    const response = yield call(getTrialPackService);

    if (response && !response.error) {
        yield put({ type: types.GET_TRIAL_PACK_SUCCESS, response });
    } else {
        yield put({ type: types.GET_TRIAL_PACK_ERROR, response });
    }
}

function* userPackageSaga() {
    const response = yield call(userPackageService);

    if (response && !response.error) {
        yield put({ type: types.USER_PACKAGE_SUCCESS, response });
    } else {
        yield put({ type: types.USER_PACKAGE_ERROR, response });
    }
}

export {
    getPackageSaga,
    getPackageDetailSaga,
    getRelatedPackageSaga,
    getTrialPackSaga,
    userPackageSaga,
};
