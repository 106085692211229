import * as types from '../action.types';

const signupAction = (data) => {
    return {
        type: types.SIGNUP,
        data,
    };
};

const signinAction = (data) => {
    return {
        type: types.SIGNIN,
        data,
    };
};

const authCheckAction = () => {
    return {
        type: types.AUTHCHECK,
    };
};

const signoutAction = () => {
    return {
        type: types.SIGNOUT,
    };
};

const forgotPasswordAction = (data) => {
    return {
        type: types.FORGOT_PASSWORD,
        data,
    };
};

const resetPasswordAction = (data) => {
    return {
        type: types.RESET_PASSWORD,
        data,
    };
};

const errorCleanupAction = () => {
    return {
        type: types.ERROR_CLEANUP,
    };
};

const socialAccountLinkAction = (data) => {
    return {
        type: types.SOCIAL_ACCOUNT_LINK,
        data,
    };
};

const socialAccountUnLinkAction = (data) => {
    return {
        type: types.SOCIAL_ACCOUNT_UNLINK,
        data,
    };
};

const verifySignupAction = (data) => {
    return {
        type: types.VERIFY_SIGNUP,
        data,
    };
};

const sendOTPAction = (data) => {
    return {
        type: types.SEND_OTP,
        data,
    };
};

const clearChangePassAction = () => {
    return {
        type: types.CHANGE_PASS_CLEANUP,
    };
};

const signInOTPAction = (data) => {
    return {
        type: types.SIGNIN_OTP,
        data,
    };
};

const signUpOTPAction = (data) => {
    return {
        type: types.SIGNUP_OTP,
        data,
    };
};

export {
    signupAction,
    signinAction,
    authCheckAction,
    signoutAction,
    forgotPasswordAction,
    resetPasswordAction,
    errorCleanupAction,
    socialAccountLinkAction,
    socialAccountUnLinkAction,
    verifySignupAction,
    sendOTPAction,
    clearChangePassAction,
    signInOTPAction,
    signUpOTPAction,
};
