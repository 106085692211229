import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#f0eff4',
        },
        secondary: {
            light: '#fff34c',
            main: '#ffdc00',
            dark: '#ffe019',
            contrastText: '#0f0b08',
        },
        background: {
            paper: '#303234',
            default: '#0b0a0d',
            dark: '#000000',
        },
        info: {
            light: '#2e85f3',
            main: '#1778f2',
            brand: '#e2e2e2',
        },
        text: {
            primary: '#b1b5b8',
            secondary: '#7f8386',
            disabled: '#4e5154',
            danger: '#db162f',
            success: '#16DB2F',
        },
        active: {
            primary: '#44d399',
            expiry: '#ef5d17',
        },
    },
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#ffdc00',
                },
                '&$active': {
                    color: '#f0eff4',
                },
            },
            active: {},
            completed: {},
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(48,51,54,0.9)',
            },
        },
    },
});

export default theme;
