import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (isLoggedIn ? <Component {...props} /> : null)}
    />
);

export default PrivateRoute;
